<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="useractivityrp" />
    <v-container>
      <v-card>
        <v-row justify="space-between" class="ma-1 pa-2">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              hide-details
              style="border-radius: 8px; border-color: #c0c0c0"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="7" style="text-align: right !important">
            <v-btn
              class="text-capitalize"
              style="
                color: #424242;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              @click="showFilterTools = !showFilterTools"
            >
              <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
              {{ $t("filter") }}
            </v-btn>

            <v-btn
              outlined
              @click="exportExcel"
              style="
                color: #a6cc39;
                font-size: 16px;
                border: 1px solid #c0c0c0;
                box-shadow: unset !important;
                border-radius: 8px;
                background: #ffffff 0% 0% no-repeat padding-box;
              "
              class="ml-3 text-capitalize"
            >
              <v-icon class="mr-2 mb-1">mdi-export-variant</v-icon>
              {{ $t("exportexcel") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="showFilterTools" class="ma-1 px-2">
          <v-col style="margin-top: 14px" lg="3">
            <v-row>
              <v-col cols="12" lg="6">
                <label
                  for="viewdatefrom"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("viewdatefrom") }}</label
                >
                <v-menu
                  v-model="frommenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startdate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      readonly
                      v-bind="attrs"
                      placeholder="yyyy/mm/dd"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    @change="StartViewDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="6">
                <label
                  for="to"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("to") }}</label
                >
                <v-menu
                  v-model="tomenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddate"
                      prepend-inner-icon="mdi-calendar-blank-outline"
                      outlined
                      dense
                      placeholder="yyyy/mm/dd"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    :allowed-dates="allowedEndDates"
                    @input="tomenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="showFilterTools" class="ma-1 px-2">
          <v-col cols="12" lg="9">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="5" xl="4" class="pre-fix">
                <label
                  for="group"
                  style="
                    font-size: 16px;
                    color: #424242;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("group") }}:</label
                >
                <v-select
                  v-model="dept"
                  :items="deptitems"
                  hide-details
                  item-text="groupName"
                  item-value="groupID"
                  hide-no-data
                  solodense
                  dense
                  outlined
                  @change="(event) => SelectGroup(event)"
                  @blur="clearSearchWord(searchtype)"
                  solo
                  clearable
                  multiple
                  placeholder="-- Please select --"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchgroup"
                          :placeholder="$t('search')"
                          @input="searchInLists(), (searchtype = 'group')"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item ripple @click="getSelectAll">
                      <v-list-item-action>
                        <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                          icon
                        }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.groupName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ dept.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="5" xl="4" class="pre-fix">
                <label
                  for="user"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                  "
                  >{{ $t("user") }}:</label
                >
                <v-select
                  v-model="userLISTD"
                  :items="useritems"
                  solodense
                  dense
                  outlined
                  hide-details
                  item-text="fullName"
                  item-value="userID"
                  hide-no-data
                  @change="(event) => SelectUser(event)"
                  @blur="clearSearchWord(searchtype)"
                  solo
                  clearable
                  multiple
                  placeholder="-- Please select --"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          v-model="searchuser"
                          :placeholder="$t('search')"
                          @input="searchInLists(), (searchtype = 'user')"
                          autocomplete="off"
                          hide-details
                          class="searchinlist"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item ripple @click="SelectAllUser">
                      <v-list-item-action>
                        <v-icon
                          :color="userLISTD.length > 0 ? '#a6cc39' : ''"
                          >{{ Usericon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.fullName }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption"
                      >(+{{ userLISTD.length - 1 }} others)</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" lg="4" style="padding-top: 2.2rem">
                <v-btn
                  text
                  class="text-capitalize"
                  height="40"
                  style="
                    border: 1px solid #c0c0c0;
                    border-radius: 8px;
                    color: #424242;
                    font-size: 16px;
                  "
                  @click="clearAll()"
                  >{{ $t("clearall") }}</v-btn
                >
                <v-btn
                  width="120"
                  height="40"
                  class="text-capitalize btn_hover_effect ml-5"
                  style="
                    color: #ffff;
                    border: 1px solid #a6cc39;
                    border-radius: 8px;
                    font-size: 16px;
                  "
                  color="#A6CC39"
                  @click="GetActivityReport()"
                  >{{ $t("search") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col class="pa-4 ma-1">
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="reportList"
            :search="search"
            hide-default-footer
            @page-count="pageCount = $event"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="pt-6 report-table"
            :single-expand="true"
            item-key="userID"
            show-expand
          >
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length - 6"></td>
              <td :colspan="headers.length - 5" style="padding-right: 0">
                <table class="table">
                  <caption></caption>
                  <tr>
                    <th></th>
                  </tr>
                  <tr v-for="(subItem, i) in item.content" :key="i">
                    <td
                      :colspan="headers.FileName"
                      class="content-filename text-left"
                    >
                      {{ item.contentCount == 0 ? null : subItem.fileName }}
                    </td>
                    <td
                      :colspan="headers.contentCount"
                      class="content-name text-left"
                    >
                      {{ item.contentCount == 0 ? null : subItem.contentName }}
                    </td>
                    <td :colspan="headers.type" class="content-type text-left">
                      {{ item.contentCount == 0 ? null : subItem.type }}
                    </td>
                    <td :colspan="headers.time" class="content-time text-left">
                      {{ item.contentCount == 0 ? null : subItem.time }}
                    </td>
                    <td
                      :colspan="headers.dateTime"
                      class="content-datetime text-left"
                    >
                      {{ item.contentCount == 0 ? null : subItem.dateTime }}
                    </td>
                    <td
                      :colspan="headers.viewTime"
                      class="content-viewtime text-left"
                    >
                      {{ item.contentCount == 0 ? null : subItem.viewTime }}
                    </td>
                  </tr>
                </table>
              </td>
            </template>
          </v-data-table>
          <v-row justify="space-between" class="mx-6 my-4">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  v-model="page"
                  class="pagination"
                  :length="pageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-card>
    </v-container>
    <!-- export -->
    <v-dialog v-model="exportDialog" persistent width="735">
      <v-card>
        <v-card-title
          style="font-size: 30px; color: #ffffff; height: 84px"
          class="secondary"
        >
          <p>{{ $t("export") }}</p>
        </v-card-title>

        <v-card-text>
          <div style="margin-top: 1rem; margin-right: 1rem">
            <v-row>
              <v-col>
                <label
                  for="filetype"
                  style="
                    margin-left: 1rem;
                    font-size: 16px;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("filetype") }}</label
                >
                <v-text-field
                  style="font-size: 16px"
                  class="file-type"
                  outlined
                  dense
                  :value="$t('excel')"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label
                  for="startdate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                    margin-left: 1rem;
                  "
                >
                  {{ $t("startdate") }}
                  <b style="color: red">*</b>
                </label>
                <v-menu
                  v-model="startmenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="exportSdate"
                      :placeholder="$t('selectdate')"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="exportSdate"
                    @input="startmenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label
                  for="enddate"
                  style="
                    font-size: 16px;
                    color: #47484b;
                    font-weight: bold;
                    margin-top: 5px;
                    margin-left: 1rem;
                  "
                >
                  {{ $t("enddate") }}
                  <b style="color: red">*</b>
                </label>
                <v-menu
                  v-model="endmenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="exportEdate"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      clearable
                      :placeholder="$t('selectdate')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="exportEdate"
                    @input="ExportstartDateDis"
                    :allowed-dates="allowedDatesExport"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="margin-right: 8px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            style="font-size: 20px"
            width="100"
            text
            @click="cancelClear()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize white--text"
            width="100"
            style="font-size: 20px"
            color="#6CC8A0"
            @click="exportExcel"
            >{{ $t("submit") }}</v-btn
          >
          <!-- exportUser -->
          <p style="height: 60px"></p>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div id="snackbar">Downloading..</div>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import exceljs from "exceljs";
import { saveAs } from "file-saver";
export default {
  components: {},
  data: () => ({
    clear: false,
    showFilterTools: false,
    userLISTD: [],
    dept: [],
    searchtype: null,
    enddate: "",
    userCopy: [],
    groupCopy: [],
    searchgroup: null,
    searchuser: null,
    expanded: [],
    singleExpand: true,
    permissionDialog: false,
    permissionMessage: "",
    breadcrumbs: [
      {
        sidebar_tilte: "home",
      },
      {
        text: "useractivityrp",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    permission: {
      viewActivity: true,
    },
    panel: [],
    useritems: [],
    deptitems: [],
    tempuseritem: [],
    //dept: null,
    search: "",
    //userLISTD: null,
    url:
      "EmployeeEXT/GetEmployeeExtAll?Id=" + localStorage.getItem("companyID"),
    httpVerb: "get",
    ActivitiyTableModel: false,
    ViewActivityArray: [],
    isFirstTime: true,
    frommenu: false,
    tomenu: false,
    startmenu: false,
    endmenu: false,
    allowedDates: (val) => new Date(val),
    //allowedDates: new Date().toISOString().substr(0, 10),
    tmpendDate: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
    tmpendEDate: (val) => parseInt(val.split("-")[2], 10) % 2 === 0,
    startdate: new Date().toISOString().substr(0, 10),
    //enddate: new Date().toISOString().substr(0, 10),
    exportSdate: new Date().toISOString().substr(0, 10),
    //exportEdate: new Date().toISOString().substr(0, 10),
    exportDialog: false,
    currentdate: new Date().toISOString().substr(0, 10),
    reportList: [],
    clickedRowId: null,
    loading: true,
    newdateoffset: new Date().getTimezoneOffset(),
  }),
  computed: {
    selectAll() {
      return this.dept.length === this.deptitems.length;
    },
    selectAllUser() {
      return this.userLISTD.length === this.useritems.length;
    },

    icon() {
      if (this.selectAll) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    Usericon() {
      if (this.selectAllUser) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },

    enddate: {
      get() {
        let temp = new Date().toISOString().substr(0, 10);
        return temp;
      },
      set() {},
    },
    exportEdate: {
      get() {
        return this.exportSdate;
      },
      set() {},
    },
    getCollections() {
      return this.collections.map((collection) => ({
        ...collection,
        empID: `${collection.empID}`,
      }));
    },
    headers() {
      return [
        // {
        //   text: "",
        //   value: "",
        //   sortable: false
        // },
        {
          text: this.$t("employeeId"),
          value: "empID",
          align: "left",
          width: "160px",
        },
        {
          text: this.$t("fullnamesm"),
          value: "username",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("group"),
          value: "departmentName",
          align: "left",
          width: "200px",
        },
        // {
        //   text: this.$t("ROLE"),
        //   value: "level",
        //   align: "left",
        //   width: "130px"
        // },
        // {
        //   text: this.$t("content"),
        //   value: "content",
        //   align: "left"
        // },
        {
          text: this.$t("view"),
          value: "view",
          align: "left",
          width: "110px",
        },
        // {
        //   text: this.$t("request"),
        //   value: "request",
        //   align: "left"
        // }
        {
          text: this.$t("filenameL"),
          value: "contentCount",
          align: "left",
          width: "210px",
          class: "table_header",
        },
        {
          text: this.$t("content"),
          // value: "contentCount",
          align: "left",
          width: "210px",
        },
        {
          text: this.$t("Type"),
          value: "type",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("time"),
          value: "time",
          align: "left",
          width: "145px",
        },
        {
          text: this.$t("date-time"),
          value: "time",
          align: "left",
          width: "160px",
        },
        {
          text: this.$t("viewtime"),
          value: "time",
          align: "left",
          width: "190px",
        },
        // {
        //   text: this.$t("like"),
        //   value: "like",
        //   align: "center",
        // },
        // {
        //   text: this.$t("time"),
        //   value: "time",
        //   align: "left"
        // }
        // {
        //   text: "GENDER",
        //   value: "gender",
        //   align: "center",
        // },
        // {
        //   text: "EMAIL",
        //   value: "email",
        // },
        // {
        //   text: "JOIN DATE",
        //   value: "joinDate",
        //   align: "center",
        // },
        // {
        //   text: "MANAGE",
        //   value: "",
        //   align: "left",
        // },
        /*  {
          text: "",
          value: "",
          align: "left",
        }, */
      ];
    },
  },
  watch: {
    // userLISTD() {
    //   this.GetActivityReport();
    // },
    startdate() {
      this.startdate = this.startdate
        ? this.startdate
        : new Date().toISOString().substr(0, 10);
    },
    enddate() {
      this.enddate = this.enddate ? this.enddate : "";
      this.exportEdate = this.enddate;
    },
  },
  methods: {
    clearAll() {
      this.userLISTD = [];
      this.dept = [];
      this.enddate = "";
      this.startdate = "";
      this.clear = true;
      this.GetActivityReport();
    },
    clearSearchWord(val) {
      switch (val) {
        case "group":
          this.searchgroup = null;
          break;
        case "user":
          this.searchuser = null;
          break;
        default:
          console.log("clear");
      }
      this.searchInLists();
    },
    searchInLists() {
      switch (this.searchtype) {
        case "group":
          if (!this.searchgroup) {
            this.deptitems = this.groupCopy;
          }

          this.deptitems =
            this.searchgroup != null
              ? this.groupCopy.filter((x) => {
                  return (
                    x.groupName
                      .toLowerCase()
                      .indexOf(this.searchgroup.toLowerCase()) > -1
                  );
                })
              : this.groupCopy;
          break;
        case "user":
          if (!this.searchuser) {
            this.useritems = this.userCopy;
          }

          this.useritems =
            this.searchuser != null
              ? this.userCopy.filter((x) => {
                  return (
                    x.fullName
                      .toLowerCase()
                      .indexOf(this.searchuser.toLowerCase()) > -1
                  );
                })
              : this.userCopy;
          break;
        default:
          console.log("Searched...");
      }
    },

    getSelectAll() {
      if (this.dept.length == 0) {
        this.dept = this.deptitems.map(({ groupID }) => groupID);
      } else {
        this.dept = [];
      }
    },
    SelectAllUser() {
      if (this.userLISTD.length == 0) {
        this.userLISTD = this.useritems.map(({ userID }) => userID);
      } else {
        this.userLISTD = [];
      }
    },

    allowedDatesExport(val) {
      return val >= this.exportSdate;
    },
    calcResexp(e) {
      this.tmpendEDate = e;
    },
    ExportstartDateDis(selected) {
      this.calcResexp(selected);
      this.endmenu = false;
      this.allowedDatesExport.exportEdate = selected;
      if (this.exportEdate < this.allowedDatesExport.exportEdate) {
        this.exportEdate = this.allowedDatesExport.exportEdate;
      }
    },
    StartViewDate() {
      if (this.startdate > this.enddate) {
        this.enddate = this.startdate;
      }
    },
    allowedDatesEnd(val) {
      return val >= this.tmpendDate;
    },
    allowedEndDates(val) {
      return val >= this.startdate;
    },
    calcRes(e) {
      this.tmpendDate = e;
      this.exportSdate = e;
    },

    startDateDis(selected) {
      this.calcRes(selected);
      this.frommenu = false;
      this.allowedDatesEnd.enddate = selected;
      if (this.enddate < this.allowedDatesEnd.enddate) {
        this.enddate = this.allowedDatesEnd.enddate;
      }
    },
    SelectGroup(event) {
      this.useritems = [];
      this.userLISTD = [];
      if (event.length > 0) {
        let temp = this.deptitems.find((x) => x.groupID == event);
        if (temp != undefined) {
          for (let i of temp.groupEmployee) {
            this.useritems.push({
              fullName: i.employeeName,
              userID: i.employeeID,
            });
          }
        }
      } else {
        this.useritems = this.tempuseritem;
        this.GetUserItems();
        this.GetActivityReport();
      }
    },
    SelectUser(event) {
      if (event.length == 0) {
        if (
          this.userLISTD.length == 0 &&
          this.startdate == "" &&
          this.enddate == ""
        ) {
          this.useritems = [];
        }
      }
    },
    async getDepartment() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      if (res.data.status == 0) {
        let temp = res.data.data;
        self.deptitems = temp.sort((a, b) =>
          a.groupName.toLowerCase() > b.groupName.toLowerCase() ? 1 : -1
        );
        self.groupCopy = self.deptitems;
      }
    },
    cancelClear() {
      this.exportDialog = false;
      this.exportSdate = this.startdate;
      this.exportEdate = this.enddate;
    },
    GetUserItems() {
      let that = this;
      axios
        .get(
          `${that.web_url}EmployeeEXT/GetEmployeeExtAll?Id=` +
            localStorage.getItem("companyID")
        )
        //.post(`${that.web_url}User/GetUserDetail`)
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data.map((v) => ({
              ...v,
              select: false,
            }));
            that.useritems = temp.sort((a, b) =>
              a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
            );
            that.tempuseritem = that.useritems;
            that.userCopy = that.useritems;
          }
        })
        .catch(function (err) {
          throw err;
        });
      return that.useritems.map((item, index) => ({ ...item, no: ++index }));
    },
    async ExportClick() {
      let self = this;
      let checkPermission = await this.checkpermission(36);
      if (checkPermission) {
        self.exportDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to export User Activity.";
      }
    },
    async checkPermissionView() {
      let checkPermission = await this.checkpermission(35);
      this.permission.viewActivity = checkPermission;
    },
    rowClicked(id) {
      if (this.clickedRowId == id) {
        this.clickedRowId = null;
      } else {
        this.clickedRowId = id;
      }
    },

    changeGenderFormat(gender) {
      if (gender) return gender[0].toUpperCase();
      else return "";
    },
    ChangeDate(datejoin) {
      if (datejoin) {
        return moment(String(datejoin)).format("MM/DD/YYYY");
      }
    },
    async exportExcel() {
      this.exportDialog = false;
      const headers = [
        "EMPLOYEEID",
        "FULLNAME",
        "GROUP",
        // "ROLE", //LEVEL
        "VIEW",
        //"REQUEST",
        "FILENAME",
        "CONTENT",
        "TYPE",
        "TIME",
        "DATE-TIME",
        "VIEW TIME",
      ];
      const keys = [
        "empID",
        "username",
        "departmentName",
        // "level",
        "view",
        //"request",
        "fileName",
        // "contentCount",
        "type",
        "time",
        "dateTime",
        "viewTime",
      ];
      const fileName = `${"UserActivityReport"}.xlsx`;
      const filterItems = this.reportList.map((x) => ({ ...x }));
      const data = [];
      data.push(headers);

      for (const item of filterItems) {
        let rowOne = [];

        for (const key of keys) {
          rowOne.push(item[key] || " ");
        }
        rowOne[headers.indexOf("EMPLOYEEID")] = item.empID;
        rowOne[headers.indexOf("USERNAME")] = item.username;
        rowOne[headers.indexOf("DEPARTMENT")] = item.departmentName;
        rowOne[headers.indexOf("VIEW")] = item.view;
        rowOne[headers.indexOf("FILENAME")] = item.contentCount;
        data.push(rowOne);

        data.push(
          ...item.content.map((x) => {
            return [
              " ",
              " ",
              " ",
              " ",
              x.fileName || " ",
              x.contentName || " ",
              x.type || " ",
              x.time || " ",
              x.dateTime || " ",
              x.viewTime || " ",
            ];
          })
        );
      }
      let workbook2 = new exceljs.Workbook();

      workbook2.addWorksheet("User Activity Report", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ff9900" },
          bgColor: { argb: "ff9900" },
        };
        cell.font = {
          name: "Calibri",
          size: 11,
          color: { argb: "000000" },
          bold: true,
        };
        console.log(number);
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.getRows(1, worksheet2.actualRowCount - 1).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );
      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      worksheet2.columns.forEach((column, i) => {
        column.width = headers[i].length + (i <= 10 ? 15 : 19);
      });
      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },
    async exportUser() {
      this.exportDialog = false;
      let url = null;
      let that = this;
      let temp = {
        d1: that.exportSdate ? new Date(that.exportSdate) : null,
        d2: that.exportEdate ? new Date(that.exportEdate) : null,
        companyid: localStorage.getItem("companyID"),
        offset: that.newdateoffset,
      };
      if (temp.d1) temp.d1.setUTCHours(0, 0, 0, 0);
      if (temp.d2) temp.d2.setUTCHours(23, 59, 59, 999);
      let sDate = that.exportSdate
        ? that.convertDate1(that.exportSdate) + " " + "00:00"
        : null;
      let eDate = that.exportEdate
        ? that.convertDate1(that.exportEdate) + " " + "23:59"
        : null;
      url = `${
        that.web_url
      }EmployeeEXT/ExportEmployeeReport?companyid=${localStorage.getItem(
        "companyID"
      )}&d1=${sDate}&d2=${eDate}&offset=${that.newdateoffset}`;
      axios
        .post(url)
        .then(function (response) {
          if (response.data.status == 0) {
            let URl =
              "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
              response.data.data.base64;
            let downloadLink = document.createElement("a");
            let filename = response.data.data.nameFile;

            downloadLink.href = URl;
            downloadLink.download = filename;
            downloadLink.click();
          }
          that.exportDialog = false;
          that.cancelClear();
        })
        .catch(function (err) {
          throw err;
        });
    },
    ViewPermission(id) {
      let that = this;
      that.ActivitiyTableModel = true;

      axios
        .post(`${that.web_url}Reports/ReportViewActivity?id=${parseInt(id)}`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ViewActivityArray = response.data.data;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    OnChangeSDate() {
      if (this.isFirstTime) this.enddate = this.startdate;
      this.isFirstTime = false;
    },
    convertDate(date) {
      let newDate = new Date();
      if (date != null) {
        newDate = date;
      }
      let output = null;
      if (newDate.getDate() < 10) {
        output = "0" + newDate.getDate();
      } else {
        output = newDate.getDate();
      }
      let localMonth = newDate.getMonth() + 1;
      if (localMonth < 9) {
        output = output + "/" + "0" + localMonth;
      } else {
        output = output + "/" + localMonth;
      }
      output = output + "/" + newDate.getFullYear();
      return output;
    },
    convertDate1(date) {
      let newDate = new Date();
      if (date) {
        newDate = new Date(date);
      }
      let output = null;
      output = newDate.getFullYear();
      let localMonth = newDate.getMonth() + 1;
      if (localMonth < 9) {
        output = output + "/" + "0" + localMonth;
      } else {
        output = output + "/" + localMonth;
      }
      if (newDate.getDate() < 10) {
        output = output + "/" + "0" + newDate.getDate();
      } else {
        output = output + "/" + newDate.getDate();
      }
      return output;
    },
    async GetActivityReport() {
      let self = this;
      self.loading = true;

      let request = {
        companyID: localStorage.getItem("companyID"),
        viewDateFrom: self.clear === false ? self.startdate + " 00:00" : "",
        viewDateTo: self.clear === false ? self.enddate + " 23:59" : "",
        userID: self.userLISTD ? self.userLISTD : [],
        departmentID: self.dept ? self.dept : [],
        offset: self.newdateoffset,
      };

      await axios
        .post(`${self.web_url}Reports/GetUserActivityReport`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            self.reportList = response.data.data.map((v) => ({
              ...v,
            }));
            self.clear = false;
          }
        })
        .catch(function (err) {
          throw err;
        });
      self.loading = false;
    },
    async FreshAllData() {
      let self = this;
      self.loading = true;
      let checkPermission = await self.checkpermission(35);
      if (checkPermission) {
        try {
          self.Loading = true;
          let url = null;
          let uID = 0;
          if (self.userLISTD) {
            uID = self.userLISTD;
          }
          let sDate = self.convertDate1(self.startdate) + " " + "00:00";
          let eDate = self.convertDate1(self.enddate) + " " + "23:59";
          url = `${
            self.web_url
          }EmployeeEXT/GetEmployeeExtReport?id=${localStorage.getItem(
            "companyID"
          )}&fromDate=${sDate}&toDate=${eDate}&UserID=${uID}`;
          await axios.get(url).then(function (response) {
            if (response.data.status == 0) {
              let userlist = [];
              for (let v of response.data.data) {
                v.empID = `${v.empID}`;
                v.joinDate = self.changeDateFormat(v.joinDate);
                userlist.push(v);
              }
              self.reportList = userlist;
              self.Loading = false;
            } else {
              alert(response.data.message);
            }
          });
        } catch (error) {
          alert(error);
        }
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to see User Activity.";
      }
      self.loading = false;
    },
  },
  mounted() {
    this.getDepartment();
    this.GetActivityReport();
    this.checkPermissionView();
    this.GetUserItems();
    let year = new Date().getFullYear();
    let month =
      new Date().getMonth() + 1 <= 9
        ? "0" + parseInt(new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let day =
      new Date().getDate() <= 9
        ? "0" + parseInt(new Date().getDate())
        : new Date().getDate();
    this.startDateDis(`${year}-${month}-${day}`);
    let years = new Date().getFullYear();
    let months =
      new Date().getMonth() + 1 <= 9
        ? "0" + parseInt(new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let days =
      new Date().getDate() <= 9
        ? "0" + parseInt(new Date().getDate())
        : new Date().getDate();
    this.ExportstartDateDis(`${years}-${months}-${days}`);
  },
};
</script>
<style scoped>
::v-deep .v-data-table__expand-icon.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: 1px solid !important;
  border-radius: 50% !important;
  font-size: 20px;
  color: #424242;
}
.v-select .v-chip {
  max-width: 80px;
}
.new_pre-fix .v-input__slot {
  border: 1px solid #707070;
  font-size: 16px !important;
  border-radius: 4px !important;
}
.pre-fix .v-input__slot {
  border: 1px solid #707070;
  font-size: 16px !important;
  border-radius: 4px !important;
}

@media (min-width: 960px) {
  .container {
    max-width: unset !important;
  }
}
::v-deep .v-dialog {
  border-radius: 35px !important;
}
::v-deep .v-input__slot {
  border-radius: 5px !important;
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: top;
  word-break: break-all !important;
}
@media only screen and (max-width: 990px) {
  .user-padding {
    margin-right: 0.8rem;
  }
  .from-padding {
    margin-right: 0.8rem;
  }
  .to-padding {
    margin-right: 1.9rem;
  }
}
::v-deep .date-h .v-input__slot {
  height: 50px !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .useractivity {
    height: 45px !important;
    margin-left: 2rem !important;
  }
  .search-res {
    float: unset !important;
  }
  ::v-deep .new_searchField .v-input__slot {
    width: unset;
  }
  ::v-deep .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 200px;
    font-size: 16px !important;
  }
  .iphone-res {
    width: 270px !important;
    font-size: 18px !important;
  }
}
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  ::v-deep .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 200px;
    font-size: 16px !important;
  }
  .search-res {
    float: unset !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  ::v-deep .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 200px;
    font-size: 16px !important;
  }
  .search-res {
    float: unset !important;
  }
}
::v-deep .mx-datepicker {
  display: unset !important;
}
::v-deep .mx-input {
  height: 48px;
  border-radius: 10px !important;
  border-color: #707070;
  font-size: 16px !important;
}
.ab-text {
  margin-top: 28px;
}
::v-deep .user-info[data-v-655ded1c] td:nth-child(1) {
  padding-left: 2rem !important;
}
::v-deep .theme--light.v-table {
  margin-top: 2rem;
}
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 37px !important;
}
.text-btn {
  cursor: pointer;
  font-size: 14px;
}
.picker-textfield ::v-deep .v-input__slot {
  max-width: 400px;
}
@media only screen and (max-width: 1366px) and (min-width: 1024) {
  .new_searchField .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 135px !important;
    font-size: 16px !important;
  }
  .btn {
    margin-left: unset !important;
  }
  ::v-deep .pre-fix .v-input__slot {
    width: 100px !important;
  }
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  ::v-deep .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 200px;
    font-size: 16px !important;
  }
  ::v-deep .new_searchField .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: unset !important;
    font-size: 16px !important;
  }
  .search-res {
    float: unset !important;
    margin-left: 7rem !important;
  }
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  ::v-deep .v-input__slot {
    border-radius: 5px !important;
    margin-left: 5px;
    font-size: 16px !important;
  }
  ::v-deep .new_searchField .v-input__slot {
    border-radius: 5px !important;
    margin-left: 15px;
    width: 135px !important;
    font-size: 16px !important;
  }
  .clk-btn {
    margin-left: 2rem !important;
  }
  .search-res {
    float: unset !important;
    margin-left: unset !important;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1381px) and (-webkit-min-device-pixel-ratio: 1) {
  ::v-deep .from-date .v-input__slot {
    width: 150px !important;
  }
  ::v-deep .to-date .v-input__slot {
    width: 150px !important;
  }
  .to-date {
    margin-left: 4rem !important;
  }
  .clk-btn {
    margin-left: 5.5rem !important;
  }
}
@media screen and (min-device-width: 1382px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  ::v-deep .from-date .v-input__slot {
    width: 150px !important;
  }
  ::v-deep .to-date .v-input__slot {
    width: 150px !important;
  }
  .to-date {
    margin-left: 2rem !important;
  }
  .clk-btn {
    margin-left: 2.5rem !important;
  }
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
::v-deep .searchinlist.v-text-field > .v-input__control > .v-input__slot {
  margin-left: 0px;
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  background: #f5f9e9;
}

::v-deep .table {
  table-layout: fixed;
  width: 100%;
}
::v-deep .th,
td.content-filename {
  width: 180px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
}
::v-deep .th,
td.content-name {
  width: 200px;
  overflow: hidden;
  word-break: break-all !important;
  background-color: unset;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .th,
td.content-type {
  width: 130px;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .th,
td.content-time {
  width: 140px;
  overflow: hidden;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .th,
td.content-datetime {
  width: 140px;
  overflow: hidden;
  padding-left: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
::v-deep .th,
td.content-viewtime {
  width: 175px;
  overflow: hidden;
  padding-left: 0.9rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.report-table tr:nth-child(even) {
  background: unset;
}
::v-deep
  .v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__row {
  background: #f1f1f3 !important;
}
.table_header {
  max-width: 200px !important;
}
.v-card {
  border-radius: 16px !important;
}
</style>

<style>
.report-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.report-table thead th:last-child {
  border-radius: 0px 8px 8px 0px !important;
}
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
</style>
